import React from "react";

export function Soulector(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 18" {...props}>
      <path d="M12.8 0c-.9 0-1.9.2-2.8.5A18.2 18.2 0 00.2 8.6L0 9l.2.4c2.3 3.7 5.9 6.8 9.5 8a11.7 11.7 0 004.5.6c1.6-.2 3-.6 4.5-1.4 3-1.5 5.8-4.1 7.6-7.2l.3-.4-.3-.4a19.5 19.5 0 00-9.5-8 11.8 11.8 0 00-4-.6zm1 1.8a7.2 7.2 0 014.3 12.6v-.2a5 5 0 00-4.7-6.1l-1-.1a3.2 3.2 0 01-2.2-3.7A3.2 3.2 0 0113 1.8h.8zM8.5 3.7a4.8 4.8 0 001.3 4.7 5 5 0 003.5 1.5h.5a3.2 3.2 0 012.6 3.8 3.2 3.2 0 01-2.7 2.5h-1.4A7.2 7.2 0 018.5 3.6zm14 2.8a17.8 17.8 0 012 2.5l-.2.3a18 18 0 01-2.5 2.9v-.3a9.1 9.1 0 000-6l.6.6zM4.6 6.2a9.3 9.3 0 000 6A20.1 20.1 0 012.2 9l.5-.7a19 19 0 012.2-2.5l-.1.4z" />
    </svg>
  );
}

// Icons

export function IconSpeaker(props: any) {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.57 5.12a.56.56 0 0 0-.6.07L4.7 7.02H2.83c-.3 0-.56.25-.56.56v2.82c0 .3.25.56.56.56h1.86l2.29 1.84a.54.54 0 0 0 .6.07c.19-.1.31-.29.31-.5V5.63a.56.56 0 0 0-.32-.51zM15.73 9c0-2.08-.81-4.04-2.3-5.5a.42.42 0 1 0-.6.6A6.83 6.83 0 0 1 14.9 9c0 1.86-.73 3.6-2.05 4.9a.42.42 0 0 0 .59.6 7.66 7.66 0 0 0 2.3-5.5z" />
      <path d="M13.53 9c0-1.53-.58-2.96-1.64-4.04a.42.42 0 1 0-.6.6c.9.91 1.4 2.14 1.4 3.44 0 1.31-.5 2.53-1.4 3.45a.42.42 0 0 0 .6.6A5.74 5.74 0 0 0 13.52 9z" />
      <path d="M10.1 6.42a.42.42 0 1 0-.6.59c.53.53.81 1.23.81 1.99 0 .75-.28 1.46-.8 1.99a.42.42 0 0 0 .6.6A3.65 3.65 0 0 0 11.14 9c0-.98-.36-1.9-1.04-2.58z" />
    </svg>
  );
}

export function IconPlay(props: any) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15,10.001c0,0.299-0.305,0.514-0.305,0.514l-8.561,5.303C5.51,16.227,5,15.924,5,15.149V4.852
 c0-0.777,0.51-1.078,1.135-0.67l8.561,5.305C14.695,9.487,15,9.702,15,10.001z"
      />
    </svg>
  );
}

export function IconPause(props: any) {
  return (
    <svg viewBox="0 0 43 43" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.7 34.9h-5.28a.67.67 0 0 1-.67-.66V9.05c0-.36.3-.66.67-.66h5.28c.38 0 .68.3.68.66v25.2c0 .36-.3.65-.68.65zM31.29 34.9H26a.67.67 0 0 1-.67-.66V9.05c0-.36.3-.66.67-.66h5.29c.37 0 .67.3.67.66v25.2c0 .36-.3.65-.67.65z" />
    </svg>
  );
}

export function IconShuffle(props: any) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.093 6.694h.92v2.862L20 5.532l-3.988-4.025v2.387h-.92c-3.694 0-5.776 2.738-7.614 5.152-1.652 2.172-3.08 4.05-5.386 4.05H0v2.798h2.093c3.694 0 5.776-2.736 7.614-5.152 1.652-2.173 3.08-4.048 5.386-4.048zM5.41 8.458c.158-.203.316-.412.477-.623A47.32 47.32 0 017.139 6.24C5.817 5.005 4.224 4.095 2.093 4.095H0v2.8h2.093c1.327 0 2.362.622 3.317 1.563zm10.602 4.836h-.92c-1.407 0-2.487-.7-3.491-1.738l-.303.397c-.441.58-.915 1.201-1.439 1.818 1.356 1.324 3 2.324 5.232 2.324h.92v2.398L20 14.468l-3.988-4.025v2.851z" />
    </svg>
  );
}

export function IconFastBackward(props: any) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.959,4.571L10.756,9.52c0,0-0.279,0.201-0.279,0.481s0.279,0.479,0.279,0.479l7.203,4.951
	C18.531,15.811,19,15.53,19,14.805V5.196C19,4.469,18.531,4.188,17.959,4.571z M8.883,4.571L1.68,9.52c0,0-0.279,0.201-0.279,0.481
	S1.68,10.48,1.68,10.48l7.203,4.951c0.572,0.381,1.041,0.1,1.041-0.625v-9.61C9.924,4.469,9.455,4.188,8.883,4.571z"
      />
    </svg>
  );
}

export function IconFastForward(props: any) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.244,9.52L2.041,4.571C1.469,4.188,1,4.469,1,5.196v9.609c0,0.725,0.469,1.006,1.041,0.625l7.203-4.951
	c0,0,0.279-0.199,0.279-0.478C9.523,9.721,9.244,9.52,9.244,9.52z M18.6,10.001c0,0.279-0.279,0.478-0.279,0.478l-7.203,4.951
	c-0.572,0.381-1.041,0.1-1.041-0.625V5.196c0-0.727,0.469-1.008,1.041-0.625L18.32,9.52C18.32,9.52,18.6,9.721,18.6,10.001z"
      />
    </svg>
  );
}

export function IconSkipThirty(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10.54.43v2.93h-.26A10.09 10.09 0 00.43 13.42c0 5.56 4.53 10.07 10.1 10.07 5.59 0 10.11-4.5 10.11-10.07h-2.52a7.57 7.57 0 01-15.16 0 7.56 7.56 0 017.32-7.55h.26v3.3l4.8-4.3-4.8-4.44zm.41 13.42v-1.16c0-1.25.76-1.98 1.89-1.98s1.89.73 1.89 1.98v1.16c0 1.3-.76 1.98-1.89 1.98s-1.89-.69-1.89-1.98zm1.89-2.31c-.6 0-.96.46-.96 1.11v1.24c0 .7.32 1.11.96 1.11.66 0 .96-.44.96-1.1v-1.25c0-.68-.38-1.11-.96-1.11zm-5.86.11v-.84h3.5v.81L9.1 12.74v.08h.18c.68 0 1.3.47 1.3 1.37 0 1-.77 1.64-1.86 1.64-1.18 0-1.9-.7-1.9-1.67v-.2h.94v.17c0 .48.33.87.96.87.58 0 .93-.34.93-.81 0-.47-.34-.7-.8-.7h-.87v-.84l1.22-1H6.98z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function IconBackThirty(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10.55.43v2.93h.26c5.46.13 9.85 4.58 9.85 10.06 0 5.56-4.53 10.07-10.1 10.07C4.96 23.5.44 19 .44 13.42h2.52a7.57 7.57 0 0015.16 0 7.56 7.56 0 00-7.32-7.55h-.26v3.3l-4.8-4.3 4.8-4.44zm.42 13.42v-1.16c0-1.25.76-1.98 1.89-1.98 1.12 0 1.88.73 1.88 1.98v1.16c0 1.3-.76 1.98-1.88 1.98-1.13 0-1.89-.69-1.89-1.98zm1.89-2.31c-.6 0-.96.46-.96 1.11v1.24c0 .7.32 1.11.96 1.11.66 0 .96-.44.96-1.1v-1.25c0-.68-.38-1.11-.96-1.11zM7 11.65v-.84h3.49v.81l-1.37 1.12v.08h.19c.68 0 1.3.47 1.3 1.37 0 1-.77 1.64-1.87 1.64-1.17 0-1.88-.7-1.88-1.67v-.2h.93v.17c0 .48.33.87.95.87.59 0 .93-.34.93-.81 0-.47-.33-.7-.8-.7h-.86v-.84l1.22-1H7z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function IconSearch(props: any) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.18 11.93h-.68l-.26-.22a5.67 5.67 0 001.33-3.64 5.54 5.54 0 10-11.07 0 5.55 5.55 0 009.19 4.24l.25.22v.68l4.28 4.29 1.28-1.29-4.32-4.28zm-5.12 0A3.84 3.84 0 014.2 8.07c0-2.14 1.7-3.86 3.85-3.86a3.84 3.84 0 013.84 3.86c0 2.14-1.7 3.86-3.84 3.86z" />
    </svg>
  );
}

export function IconTimes(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
    </svg>
  );
}

export function IconSoundcloud(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 11" {...props}>
      <path d="M.78 9.1l.38-1.63L.78 5.8C.77 5.75.68 5.7.56 5.7s-.22.04-.22.09L0 7.47.33 9.1c.01.06.1.1.23.1.11 0 .2-.04.22-.1zm2.37.9l.32-2.51-.32-3.88c0-.1-.13-.17-.27-.17-.15 0-.27.08-.28.17L2.32 7.5l.28 2.5c0 .1.13.18.28.18.14 0 .26-.08.27-.18zm2.37-.05l.27-2.46-.27-5.16c0-.12-.15-.22-.33-.22-.17 0-.32.1-.32.22L4.63 7.5l.24 2.47c0 .12.15.21.32.21.18 0 .32-.1.33-.22zm2.36-.03l.22-2.43-.22-5.2c0-.14-.17-.25-.37-.25-.2 0-.36.11-.37.25l-.2 5.2.2 2.43c0 .14.17.25.37.25.2 0 .37-.1.37-.25zm2.37-.03l.17-2.4-.17-5.96c0-.16-.2-.3-.42-.3-.22 0-.4.14-.41.3L9.26 7.5l.16 2.4c0 .16.19.3.41.3.23 0 .41-.14.42-.3zm1.65.3h8.27c1.65 0 2.98-1.3 2.98-2.89 0-1.6-1.33-2.88-2.98-2.88-.41 0-.8.08-1.15.22A5.2 5.2 0 0013.77 0c-.65 0-1.3.12-1.9.34-.23.09-.3.18-.3.35v9.15a.35.35 0 00.33.34z" />
    </svg>
  );
}
